import React,{useEffect,useState,useRef}  from "react";
import styled from "styled-components";
import { bottom } from "styled-system";
import FormGenerator from "./FormGenerator";
import ListMap from "./ListMap";
import {ucfirst,GetUrlParams} from "../../utils/HelperFunctions";
import {Button,SmallButton1,Error} from "../../styles/Global";


const Container = styled.div`
    width:100%;
    height:100%;
    position:relative;
`

const Title = styled.div`
    text-align: left;
    box-sizing: border-box;
    outline: 0;
    font-family: Poppins,sans-serif;
    font-weight: 300;
    line-height: 1.2;
    color: inherit;
    font-size: 1.125rem;
    margin-bottom: 0;
    margin-top: 0;
    margin-left:10px;
`
const Right = styled.div`
position:absolute;
right:25px;
`

const FlexContainer = styled.div`
    position:relative;
    height:100%;
    width:100%;
    display:flex;
    flex-direction:column;
`

const Top = styled.div`
  flex: 1 1 auto;
  overflow:hidden;
  min-height:40%;
  overflow-y:scroll;

`

const Bottom = styled.div`
    flex: 1 1 auto;
    overflow:hidden;
    min-height:40%;
`

const Header = styled.div`
color: #212529;
text-align: left;
font-size: 14px;
font-weight: 300;
box-sizing: border-box;
outline: 0;
display: flex;
flex-wrap: wrap;
background: #fff;
padding: 14px 10px;
box-shadow: 1px 0 20px rgba(0,0,0,.08);

`

export default function EditMap({data}) {
    const [editID, setEditID] = useState(null);
    const [newUserCounter, setNewUserCounter] = useState(0);
    const [count, setCount] = useState(null);
    const [extra, setExtra] = useState({});

   
    useEffect(() => {        
        console.log("datatype",data.apitype);
     }, [data]);


     useEffect(() => {
        let params = GetUrlParams();
        if (params.id) {
            setEditID(params.id);
        }
        console.log("params!",params);
     }, []);

    
    function onEdit(id){
        console.log("seteditid ",id);
        setEditID(id);
    }

    function refresh(){
        setEditID(null);
    }

    function extraSet(extragot) {
        setExtra(extragot);
        console.log("yes, extra set ",extragot);
    }

    function addNewUser(){
        setNewUserCounter(newUserCounter+1);
    }

    function countChanged(cnt) {
            setCount(cnt);
    }

    return (
        <Container>
        <FlexContainer>
        <Header>
                <Title>
                    {ucfirst(data.nameMultiple)}
                </Title>
                <Right>
                    {
                        data.options.indexOf("insert") != -1 &&  <SmallButton1 onClick={addNewUser}>Add new {data.nameSingle}</SmallButton1>
                    }
                   
                </Right>
            </Header>
         {editID &&
        <Top>
        <FormGenerator extra={extra} type={data.apitype} id={editID} data={data} callback={refresh}></FormGenerator>
        </Top>
        }
        <Bottom>
        <ListMap extraSet={extraSet} countChanged={countChanged} newUserCounter={newUserCounter} type={data.apitype} id={editID} data={data} onEdit={onEdit}></ListMap>
        </Bottom>

        </FlexContainer>
        </Container>

    );
}