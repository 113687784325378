import React,{useEffect,useState,useRef}  from "react";
import styled from "styled-components";
import { bottom } from "styled-system";
import Edit from "./Edit";
import FormGenerator from "./FormGenerator";
import {LatLngDistance} from "../../utils/HelperFunctions"
import { PointState,checkComplete } from "../../utils/PointState";

const Marker = styled.div`
background-image: url("/img/${props => props.type==1?props.marker:"marker2.png"}");
width:35px;
height:56px;
transform: translate(-50%, -100%);
cursor:pointer;
position:absolute;
z-index:2;
`

const Circle = styled.div`
z-index:1;
  background-color:rgba(0,255,0,0.3);
  width:${props => props.rangePixels}px;
  height:${props => props.rangePixels}px;
  transform: translate(-50%, -50%);
  border-radius:50%;
`

const EditPanel = styled.div`
  z-index:3;
  background-color:white;
  border:1px solid #212529;
  min-width:100px;
  max-width:200px;
  position:absolute;
  left:18px;
  top:-56px;
`
const TextTitle = styled.div`
  margin-bottom:2px;
  font-weight:bold;
  padding:5px;
`

const TextLine = styled.div`
  color:${props => props.red==true? "red":"#212529"};
  margin-bottom:-1px;
  cursor:pointer;
  width:100%;
  border-top:1px solid #212529;
  padding:5px;
  &:hover {
    background-color: #212529;
    color:white;
  }
`

const ExtreInfoLine = styled.div`
  color:green;
  margin-bottom:-1px;
  cursor:pointer;
  width:100%;
  border-top:1px solid #212529;
  padding:5px;
`

const SVG = styled.svg`
  transform: translate(-50%, -50%);
`

const Container = styled.div`
  position:absolute;
`

export default function MapPointer(props) {
    const [value, setValue] = useState("");
    const [display,setDisplay] = useState(true);
    const dragging = useRef(false);
    const line = useRef(null);
    const starttop = useRef(0);
    const startleft = useRef(0);
    const marker = useRef();
    const curX = useRef(0);
    const curY = useRef(0);
    const mouseMoved = useRef(false);
    const [mpp, setMpp] = useState(1);
    const [markerImage, setMarkerImage] = useState("marker.png");
    const [rangePixels, setRangePixels] = useState(1);
    const [name, setName] = useState("");
    const [linePos, setLinePos] = useState({x1:0,y1:0,x2:0,y2:0,width:0,height:0});
    
    function getMetersPerPixel(){
        let metersPerPx = 156543.03392 * Math.cos(props.lat * Math.PI / 180) / Math.pow(2, props.zoom);
        return metersPerPx;
    }

    function updateState(){
        
        let image = "marker.png";
        if (props.selectedid == props.data.point_id) {
            image = "marker-active.png";
        }
        else {
            let state = PointState(props.data);
            let showtype = "all";
            if (props.filters.state == 1 || props.filters.state == 5 || props.filters.state == 6 || props.filters.state == 11) {
                showtype = "kids";
            }
            else if (props.filters.state == 2 || props.filters.state == 7 || props.filters.state == 8 || props.filters.state == 12) {
                showtype = "adult";
            }
            else if (props.filters.state == 13) {
                showtype = "campaign";
            }

            if (props.filters.state == 5) {
                if (props.data.point_text != "" && props.data.point_kid == 1) {
                   setDisplay(true);
                }
                else {
                    setDisplay(false);
                }
            }
            else if (props.filters.state == 11) {
                if (state.state == 'incomplete' && props.data.point_kid == 1) {
                   setDisplay(true);
                }
                else {
                    setDisplay(false);
                }
            }
            else if (props.filters.state == 6) {
                if (props.data.point_text != "" && props.data.point_kid == 0) {
                   setDisplay(true);
                }
                else {
                    setDisplay(false);
                }
            }
            else if (props.filters.state == 7) {
                if (props.data.point_text_adult != "" && props.data.point_adult == 1) {
                   setDisplay(true);
                }
                else {
                    setDisplay(false);
                }
            }
            else if (props.filters.state == 12) {
                if (state.state == 'incomplete' && props.data.point_adult == 1) {
                   setDisplay(true);
                }
                else {
                    setDisplay(false);
                }
            }
            else if (props.filters.state == 8) {
                if (props.data.point_text_adult != "" && props.data.point_adult == 0) {
                   setDisplay(true);
                }
                else {
                    setDisplay(false);
                }
            }
            else if (props.filters.state == 9) {
                //console.log("gameid",props.data.point_game_id)
                if (props.data.point_game_id != "" && props.data.point_game_id != null) {
                   setDisplay(true);
                }
                else {
                    setDisplay(false);
                }
            }
            else if (props.filters.state == 10) {
                if (props.data.point_game_id_adult != "" && props.data.point_game_id_adult != null) {
                   setDisplay(true);
                }
                else {
                    setDisplay(false);
                }
            }
            else if (props.filters.state == 13) {
                if (props.data.point_campaigns != "" && props.data.point_campaigns != null) {
                   setDisplay(true);
                }
                else {
                    setDisplay(false);
                }
            }
            else {
                setDisplay(true);
            }

            let check = null;

            if (props.data.point_id == '9568') {
                console.log("mappointer",props.filters.state,state.state);
            }


            switch (showtype) {
                case "all":
                    if (state.state == "complete" && state.type == "kid") {
                        image = "marker-yellow-kid.png";
                    }
                    else if (state.state == "complete" && state.type == "adult") {
                        image = "marker-yellow-adult.png";
                    }
                    else if (state.state == "complete" && state.type == "none" && state.completetype == "kid") {
                        image = "marker-gray-kid.png";
                    }
                    else if (state.state == "complete" && state.type == "none" && state.completetype == "adult") {
                        image = "marker-gray-adult.png";
                    }
                    else if (state.state == "complete" && state.type == "none") {
                        image = "marker-gray.png";
                    }
                    else if (state.state == "incomplete" && state.type != "none") {
                        image = "marker-red.png";
                    }
                    else if (state.state == "incomplete" && state.type == "none") {
                        image = "marker-pink.png";
                    }
        
                    if (state.state == "complete" && state.completetype == "kid" && (state.type == "adult" || state.type == "both")) {
                        image = "marker-red.png";
                    }
                    else if (state.state == "complete" && state.completetype == "adult" && (state.type == "kid" || state.type == "both")) {
                        image = "marker-red.png";
                    }
                break;
                case "kids":
                    check = checkComplete("kid",props.data);
                    if (state.state == "complete" && state.type == "kid") {
                        image = "marker-yellow-kid.png";
                    }
                    else if (state.state == "complete" &&  state.type == 'both') {
                        image = "marker.png";
                    }   
                    else if (state.state == "complete" && state.type == "adult" && check == true) {
                        image = "marker-gray-kid.png";
                    }
                    else if (state.state == "complete" && state.type == "none" && state.completetype == "kid") {
                        image = "marker-gray-kid.png";
                    }
                    else if (state.state == "complete" && state.type == "none" && state.completetype == "adult" && check == true) {
                        image = "marker-gray-adult.png";
                    }
                    else if (state.state == "complete" && state.type == "none") {
                        image = "marker-gray.png";
                    }
                    else if (state.state == "incomplete" && state.type != "none") {
                        image = "marker-red.png";
                    }
                    else if (state.state == "incomplete" && state.type == "none") {
                        image = "marker-pink.png";
                    }
        
                    if (state.state == "complete" && state.completetype == "kid" && (state.type == "adult")) {
                        image = "marker-gray-kid.png";
                    }

                    else if (state.state == "complete" && state.completetype == "kid" && (state.type == "both")) {
                        image = "marker-yellow-kid.png";
                    }

                    else if (state.state == "complete" && state.completetype == "adult" && (state.type == "both" || state.type == "kid")) {
                        image = "marker-red.png";
                    }

                    else if (state.state == "complete" && state.completetype == "adult" && (state.type == "adult" || state.type == "none")) {
                        image = "marker-pink.png";
                    }
                    

                  
                break;

                case "adult":
                    check = checkComplete("adult",props.data);
                    if (state.state == "complete" && state.type == "adult") {
                        image = "marker-yellow-adult.png";
                    }
                    else if (state.state == "complete" &&  state.type == 'both') {
                        image = "marker.png";
                    }   
                    else if (state.state == "complete" && state.type == "kid" && check == true) {
                        image = "marker-gray-adult.png";
                    }
                    else if (state.state == "complete" && state.type == "none" && state.completetype == "adult") {
                        image = "marker-gray-adult.png";
                    }
                    else if (state.state == "complete" && state.type == "none" && state.completetype == "kid" && check == true) {
                        image = "marker-gray-kid.png";
                    }
                    else if (state.state == "complete" && state.type == "none") {
                        image = "marker-gray.png";
                    }
                    else if (state.state == "incomplete" && state.type != "none") {
                        image = "marker-red.png";
                    }
                    else if (state.state == "incomplete" && state.type == "none") {
                        image = "marker-pink.png";
                    }
        
                    if (state.state == "complete" && state.completetype == "adult" && (state.type == "kid")) {
                        image = "marker-gray-adult.png";
                    }

                    else if (state.state == "complete" && state.completetype == "adult" && (state.type == "both")) {
                        image = "marker-yellow-adult.png";
                    }

                    else if (state.state == "complete" && state.completetype == "kid" && (state.type == "both" || state.type == "adult")) {
                        image = "marker-red.png";
                    }

                    else if (state.state == "complete" && state.completetype == "kid" && (state.type == "kid" || state.type == "none")) {
                        image = "marker-pink.png";
                    }
                    

                  
                break;
                case "campaign":
                    check = checkComplete("campaign",props.data);
                    if (check == true) {
                        image = "marker.png";
                    }
                    
                    else {
                        image = "marker-red.png";
                    }
                    
                break;


            }

           

        }
        
        
        
     

        // if (props.data.point_audio == "" || props.data.point_audio == null) {
        //     if (props.data.point_state == 1) {
        //         image = "marker-red.png";
        //     }
        //     else {
        //         image = "marker-pink.png";
        //     }
        // }
        // else if (props.data.point_text == "" || props.data.point_text == null) {
        //     if (props.data.point_state == 1) {
        //         image = "marker-red.png";
        //     }
        //     else {
        //         image = "marker-pink.png";
        //     }
        // }
        
        // else if (props.data.point_adult == 0 && props.data.point_kid == 0) {
        //     image = "marker-gray.png";
        // }

        // if (props.data.point_game_id != 0 && props.data.point_game_id != null && props.data.point_game_id_adult != 0 && props.data.point_game_id_adult != null) {
        //     image = "marker.png";
        // }
        // else if (props.data.point_game_id != 0 && props.data.point_game_id != null) {
        //     image = "marker-yellow-kid.png";
        // }
        // else if (props.data.point_game_id_adult != 0 && props.data.point_game_id_adult != null) {
        //     image = "marker-yellow-kid.png";
        // }

        setMarkerImage(image);




        if (props.data.point_name == "" || props.data.point_name == null) {
            setName(props.data.point_name_adult);
        }
        else {
            setName(props.data.point_name);
        }

    }

    function onMouseDown(e){
        if ((props.data.point_adult == 1 || props.data.point_kid == 1)) {
            
            return;
        }

        console.log("stop niet! ",props.data);

        var rect = e.target.getBoundingClientRect();
        var x = (rect.width/2) - (e.clientX - rect.left);
        var y = rect.height-(e.clientY - rect.top);
        dragging.current = true;
        curX.current = x;
        curY.current = y;
        props.onDragStart();
        onMove(e);
        mouseMoved.current = false;
    }
    
    function onMouseUp(){
        if (props.data.point_adult == 0 && props.data.point_kid == 0) {
            props.onDragStop();
        }
       
        console.log('mouse up');
        dragging.current = false;
        if (!mouseMoved.current) {
            if (props.editing == (props.id +"-"+props.type)) {
                console.log("SET NULL");
                props.onClick(null)
            }
            else {
                props.onClick(props.id,props.data,props.type)
            }
        }
        else {
            Action("moved");
        }
    }

    function onMove(e){
        if ((props.data.point_adult == 1 || props.data.point_kid == 1)) {
            return;
        }
        if (dragging.current) {
            
            //console.log(e.screenX,e.screenY);
            //let movedX = curX.current - e.screenX;
            //let movedY = curY.current - e.screenY;
            //let res = point2LatLng({x:e.screenX,y:e.screenY},props.map);
            // console.log(res.lat(),res.lng());
            console.log('props',props);
            props.onMove(props.id,curX.current,curY.current,props.type);

            //curX.current = e.screenX;
            //curY.current = e.screenY;

           // console.log('mouse move ',props.id);
        }
       
    }

   

    function Action(type,extraid){
        console.log("action in mappointer ",type)
        
        
        if (type == "delete") {
            if (props.type == 1) {
                props.onAction(props.data.point_id,"delete");
            }
            else  if (props.type == 2) {
                props.onAction(props.data.point_id,"deletemarker");
                
            }
            props.onClick(null);
        }
        else if (type == "moved") {
            if (props.type == 1) {
                props.onAction(props.data.point_id,"moved");
            }
            else  if (props.type == 2) {
                props.onAction(props.data.point_id,"movedmarker");
            }
        }
        else if (type == "lookaround") {
            if (props.type == 1) {
                props.onAction(props.data.point_id,"lookaround");
            }
            else  if (props.type == 2) {
                props.onAction(props.data.point_id,"lookaroundmarker");
            }
            props.onClick(null);
        }
        else if (type == "gotogame") {
            window.open("/games/?id="+extraid, "gametab");
        }
        else {
            props.onAction(props.data.point_id,type);
            props.onClick(null);
        }
        //props.onClick(null);
        
    }
    

    function drag(){
        if (dragging.current) {
            //console.log("MOUSE MOVE");
            mouseMoved.current = true;
        }
    }

    function noVisiblePoint(){
        if (props.type == 1 && props.data.point_lat_visible != 0 && props.data.point_lat_visible != null) {
            return false;
        } 
        else if (props.type == 1) {
            return true
        }
        return false;
    }

    useEffect(() => {
        //console.log("props",props.data);
        let pixelrange = props.data.point_range/getMetersPerPixel();
        setRangePixels(pixelrange);
        if (props.type == 2) {
            let l1 = props.getpixels({lat:props.data.point_lat,lng:props.data.point_lng});
            let l2 = props.getpixels({lat:props.data.point_lat_visible,lng:props.data.point_lng_visible});
            
          //  console.log("linev1",l1.x,l1.y);
          //  console.log("linev2",l2.x,l2.y);
            let diffx = l1.x-l2.x ;
            let diffy = l1.y-l2.y;
          
            let width = Math.abs(diffx)*2;
            let height = Math.abs(diffy)*2;

           // console.log("linev3",diffx,width/2);
           // console.log("linev4",diffy,height/2);
            
            length = Math.abs(diffx)+Math.abs(diffy);
            diffx = diffx + (width/2);
            diffy = diffy + (height/2);
           
            //console.log("length = ",length,"\ndiffx",diffx,"\ndiffy",diffy,"\nordiffx",l1.x-l2.x,"\norrdiffx",l1.y-l2.y,"\nwidth/2",width/2,"\nheight/2",height/2);
           // console.log("linev5",diffx,width);
           // console.log("linev6",diffy,height);


           // console.log("line ",line.current);
            var linev = {x1:diffx,y1:diffy,width:width,height:height,length:length};
            //console.log(linev);
            //console.log("linev",linev);
            setLinePos(linev);
        }
     }, [props.zoom,props.lato,props.lngo,props.lat,props.lng,props.zooming]);

     useEffect(() => {
        updateState();
     }, [props.data,props.selectedid,props.filters]);
     
     useEffect(() => {
       
        let pixelrange = props.range/getMetersPerPixel();
        setRangePixels(pixelrange);
     }, [props.zoom,props.range]);

    return (
       <>
        {display &&
       <Container>
       <Marker ref={marker} marker={markerImage} type={props.type} onMouseMove={()=>drag()} onMouseDown={(e)=>onMouseDown(e)} onMouseUp={()=>onMouseUp()}></Marker>
        {props.type == 1 &&
        <Circle rangePixels={rangePixels}></Circle>
        }
         {props.type == 2 && props.zooming == false && linePos.length>30 &&
        <SVG width={linePos.width} height={linePos.height}><line x1={linePos.width/2} y1={linePos.height/2} x2={linePos.x1} y2={linePos.y1} stroke="green"/></SVG>
        }
        {props.editing == (props.id +"-"+props.type)
            && <EditPanel>
                <TextTitle><b>{name}</b></TextTitle>
                <TextLine onClick={()=>Action("lookaround")}>Look around</TextLine>
                <TextLine onClick={()=>Action("edit")}>Edit</TextLine>
                {noVisiblePoint() && <TextLine onClick={()=>Action("markerpoint")}>Add marker point</TextLine>}
                
                {props.type == 1 && 
                <>
                <TextLine onClick={()=>Action("minrange")}>Decrease range</TextLine> 
                <TextLine onClick={()=>Action("plusrange")}>Increase range</TextLine>
                </>
                }
                {props.type == 1 && props.data.point_kid == 1 &&
                    <TextLine onClick={()=>Action("kidinactive")}>Set Kid Inactive</TextLine>
                }
                {props.type == 1 && props.data.point_kid != 1 &&
                    <TextLine onClick={()=>Action("kidactive")}>Set Kid Active</TextLine>
                }
                {props.type == 1 && props.data.point_adult == 1 &&
                    <TextLine onClick={()=>Action("adultinactive")}>Set Adult Inactive</TextLine>
                }
                {props.type == 1 && props.data.point_adult != 1 &&
                    <TextLine onClick={()=>Action("adultactive")}>Set Adult Active</TextLine>
                }
                {props.data.point_game_id != null && props.data.point_game_id != "" &&
                    <TextLine onClick={()=>Action("gotogame",props.data.point_game_id)}>Goto Kid game</TextLine>
                }
                 {props.data.point_game_id_adult != null && props.data.point_game_id_adult != "" &&
                    <TextLine onClick={()=>Action("gotogame",props.data.point_game_id_adult)}>Goto Adult game</TextLine>
                }
                 
                 <TextLine red={true} onClick={()=>Action("delete")}>Delete</TextLine>
                 {props.checkLat != 0 && props.checkLng != 0 && 
                 <ExtreInfoLine>{LatLngDistance(props.lat,props.lng,props.checkLat,props.checkLng,"K").toFixed(2)}KM</ExtreInfoLine>
                 }
            </EditPanel>
        }
        

        
       </Container>
        }
        </>
    );
}