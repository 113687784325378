import React,{useEffect,useState,useRef}  from "react";
import styled from "styled-components";
import { bottom } from "styled-system";
import Edit from "./Edit";
import FormGenerator from "./FormGenerator";
import {LatLngDistance} from "../../utils/HelperFunctions"
import { PointState,checkComplete } from "../../utils/PointState";

const Marker = styled.div`
background-image: url("/img/${props => props.type==1?props.marker:"marker2.png"}");
width:35px;
height:56px;
transform: translate(-50%, -100%);
cursor:pointer;
position:absolute;
z-index:2;
`

const Circle = styled.div`
z-index:1;
  background-color:rgba(0,255,0,0.3);
  width:${props => props.rangePixels}px;
  height:${props => props.rangePixels}px;
  transform: translate(-50%, -50%);
  border-radius:50%;
`

const EditPanel = styled.div`
  z-index:3;
  background-color:white;
  border:1px solid #212529;
  min-width:100px;
  max-width:200px;
  position:absolute;
  left:18px;
  top:-56px;
`
const TextTitle = styled.div`
  margin-bottom:2px;
  font-weight:bold;
  padding:5px;
`

const TextLine = styled.div`
  color:${props => props.red==true? "red":"#212529"};
  margin-bottom:-1px;
  cursor:pointer;
  width:100%;
  border-top:1px solid #212529;
  padding:5px;
  &:hover {
    background-color: #212529;
    color:white;
  }
`

const ExtreInfoLine = styled.div`
  color:green;
  margin-bottom:-1px;
  cursor:pointer;
  width:100%;
  border-top:1px solid #212529;
  padding:5px;
`

const SVG = styled.svg`
  transform: translate(-50%, -50%);
`

const Container = styled.div`
  position:absolute;
`

export default function SuggestionMapPointer(props) {
    const [value, setValue] = useState("");
    const dragging = useRef(false);
    const line = useRef(null);
    const starttop = useRef(0);
    const startleft = useRef(0);
    const marker = useRef();
    const curX = useRef(0);
    const curY = useRef(0);
    const mouseMoved = useRef(false);
    const [mpp, setMpp] = useState(1);
    const [markerImage, setMarkerImage] = useState("marker.png");
    const [editing, setEditing] = useState(false);
    const [rangePixels, setRangePixels] = useState(1);
    const [name, setName] = useState("");
    const [linePos, setLinePos] = useState({x1:0,y1:0,x2:0,y2:0,width:0,height:0});
    
    function getMetersPerPixel(){
        let metersPerPx = 156543.03392 * Math.cos(props.lat * Math.PI / 180) / Math.pow(2, props.zoom);
        return metersPerPx;
    }

    function updateState(){
        

        
        let image = "marker-suggestion.png";
        setMarkerImage(image);



        
       
       setName(props.data.data.suggestion_title);
       
    }

    function onMouseDown(e){
       setEditing(!editing);
    }
    
    function onMouseUp(){

    }

    function onMove(e){
       
    }

   

    function Action(type){
        console.log("action in mappointer ",type)
        if (type == "createPoint") {
            props.onClick(props.data.data);
        }   
        else if (type == "lookaround") {
            props.LookAroundMarker(props.data.data,"lookaround");
        }
    }
    

    function drag(){
        if (dragging.current) {
            //console.log("MOUSE MOVE");
            mouseMoved.current = true;
        }
    }

    function noVisiblePoint(){
        if (props.type == 1 && props.data.point_lat_visible != 0 && props.data.point_lat_visible != null) {
            return false;
        } 
        else if (props.type == 1) {
            return true
        }
        return false;
    }

    useEffect(() => {
        //console.log("props",props.data);
        let pixelrange = props.data.point_range/getMetersPerPixel();
        setRangePixels(pixelrange);
        if (props.type == 2) {
            let l1 = props.getpixels({lat:props.data.point_lat,lng:props.data.point_lng});
            let l2 = props.getpixels({lat:props.data.point_lat_visible,lng:props.data.point_lng_visible});
            
          //  console.log("linev1",l1.x,l1.y);
          //  console.log("linev2",l2.x,l2.y);
            let diffx = l1.x-l2.x ;
            let diffy = l1.y-l2.y;
          
            let width = Math.abs(diffx)*2;
            let height = Math.abs(diffy)*2;

           // console.log("linev3",diffx,width/2);
           // console.log("linev4",diffy,height/2);
            
            length = Math.abs(diffx)+Math.abs(diffy);
            diffx = diffx + (width/2);
            diffy = diffy + (height/2);
           
            //console.log("length = ",length,"\ndiffx",diffx,"\ndiffy",diffy,"\nordiffx",l1.x-l2.x,"\norrdiffx",l1.y-l2.y,"\nwidth/2",width/2,"\nheight/2",height/2);
           // console.log("linev5",diffx,width);
           // console.log("linev6",diffy,height);


           // console.log("line ",line.current);
            var linev = {x1:diffx,y1:diffy,width:width,height:height,length:length};
            //console.log(linev);
            //console.log("linev",linev);
            setLinePos(linev);
        }
     }, [props.zoom,props.lato,props.lngo,props.lat,props.lng,props.zooming]);

     useEffect(() => {
        updateState();
     }, [props.data,props.selectedid]);
     
     useEffect(() => {
       
        let pixelrange = props.range/getMetersPerPixel();
        setRangePixels(pixelrange);
     }, [props.zoom,props.range]);

    return (
       <Container>
       <Marker ref={marker} marker={markerImage} type={props.type} onMouseMove={()=>drag()} onMouseDown={(e)=>onMouseDown(e)} onMouseUp={()=>onMouseUp()}></Marker>
        {editing
            && <EditPanel>
                <TextTitle><b>{name}</b></TextTitle>
                <TextLine onClick={()=>Action("lookaround")}>Look around</TextLine>
                <TextLine onClick={()=>Action("createPoint")}>Create point</TextLine>
            </EditPanel>
        }
        

        
       </Container>
    );
}