import mainjson from '../config/main';
import { SetFieldValue } from '../utils/FormFieldMethods';


function copyData(formdata, data,setfunc){
    //console.log("copy data",data,setfunc);
    data.data["point_name_adult"] = data.data["point_name"];
    data.data["point_text_adult"] = data.data["point_text"];
    data.data["point_image_adult"] = data.data["point_image"];
    setfunc(data);
    alert("data copied")
}

function copyDataToKids(formdata, data,setfunc){
    //console.log("copy data",data,setfunc);
    data.data["point_name"] = data.data["point_name_adult"];
    data.data["point_text"] = data.data["point_text_adult"];
    data.data["point_image"] = data.data["point_image_adult"];
    setfunc(data);
    alert("data copied")
}

function copyDataToCampaignFromAdult(formdata, data,setfunc){
    //console.log("copy data",data,setfunc);
    data.data["point_name_campaign"] = data.data["point_name_adult"];
    data.data["point_text_campaign"] = data.data["point_text_adult"];
    data.data["point_image_campaign"] = data.data["point_image_adult"];
    setfunc(data);
    alert("data copied")
}

function copyDataCampaign(formdata, data,setfunc){
    //console.log("copy data",data,setfunc);
    data.data["point_name_campaign"] = data.data["point_name"];
    data.data["point_text_campaign"] = data.data["point_text"];
    data.data["point_image_campaign"] = data.data["point_image"];
    setfunc(data);
    alert("data copied")
}

function checkSection(val){
    if (val === 0 || val === "0") {
        return false;
    }
    return true;
}


export default function GetData() {
        return {
        "apitype":"points",
        "id":"point_id",
        "subidname":"point_name",
        "options":["edit","insert","delete","listoptions"],
        "nameSingle":"point",
        "nameMultiple":"points",
        "buttons":[
            {
                tab:0,
                copy:"copieer data naar volwassen",
                method:copyData
            },
            {
                tab:0,
                copy:"copieer data naar campaign",
                method:copyDataCampaign
            },
            {
                tab:1,
                copy:"copieer data naar kids",
                method:copyDataToKids
            },
            {
                tab:1,
                copy:"copieer data naar campaign",
                method:copyDataToCampaignFromAdult
            }
        ],
        "extraoptions":[
            {
                "name":"Map",
                "page":"/pointsmap/?subid=[point_section_id]&id=[point_id]",
                "type":"custom",
            },
        ],
        "tabs":[
           
            {
                id:0,
                name:"Kids"
            },
            {
                id:1,
                name:"Adult"
            },
            {
                id:3,
                name:"Campaign"
            },
            {
                id:2,
                name:"Generic"
            },
         
        ],
        "listoptions":[
            {
                "name":"Copy",
                "link":"/copypoints/?ids=[ids]",
            }
        ],
        "filters":
            [
                {
                    "key":"state",
                    "name":"State",
                    "options":[
                        {key:"4",value:"All"},
                        {key:"1",value:"Kids"},
                        {key:"2",value:"Adult"},
                        {key:"5",value:"Kids active"},
                        {key:"11",value:"Kids active - incomplete"},
                        {key:"6",value:"Kids inactive"},
                        {key:"7",value:"Adult active"},
                        {key:"12",value:"Adult active - incomplete"},
                        {key:"8",value:"Adult inactive"},
                        {key:"9",value:"Kids Game"},
                        {key:"10",value:"Adult Game"},
                        {key:"13",value:"Campaign active"},
                        
                    ]
                }
            ]
        ,
        "fields":{
            "field0":{
                "name":"Id",
                "field":"point_id",
                "type":"Display",
                "list":true,
                "tab":2,
                
            },
            "field00":{
                "name":"Kids",
                "field":"point_kid",
                "type":"CheckBox",
                "list":true,
                "tab":2,
               
            },
            "field000":{
                "name":"Adult",
                "field":"point_adult",
                "type":"CheckBox",
                "list":true,
                "tab":2,
               
            },
            "field1":{
                "name":"Name",
                "field":"point_name",
                "type":"TextInput",
                "list":true,
                "tab":0,
            },
            "field1b":{
                "name":"Name",
                "field":"point_name_adult",
                "type":"TextInput",

                "list":true,
                "tab":1,
            },
            "field1c1":{
                "name":"Name",
                "field":"point_name_campaign",
                "type":"TextInput",

                "list":true,
                "tab":3,
            },
            "field1c":{
                "name":"Media",
                "field":"point_media",
                "type":"Display",
                "list":true,
                "edit":false,
                "tab":0,

            },   
            "field2":{
                "name":"Latitude",
                "field":"point_lat",
                "type":"TextInput",
                "subtype":"Number",
                "required":true,
                "list":false,
                "tab":2,
            },
            "field3":{
                "name":"Longitude",
                "field":"point_lng",
                "type":"TextInput",
                "subtype":"Number",
                "required":true,
                "list":false,
                "tab":2,
            },
            "field4":{
                "name":"Latitude marker point",
                "field":"point_lat_visible",
                "type":"TextInput",
                "subtype":"Number",
                "required":false,
                "tab":2,
            },
            "field5":{
                "name":"Longitude marker point",
                "field":"point_lng_visible",
                "type":"TextInput",
                "subtype":"Number",
                "required":false,
                "tab":2,
            },
            "field6":{
                "name":"Range",
                "field":"point_range",
                "type":"TextInput",
                "subtype":"Number",
                "required":false,
                "tab":2,
            },
            
            "field7":{
                "name":"Text Kids",
                "field":"point_text",
                "type":"TextArea",
                "tab":0,
            },
            "field7b":{
                "name":"Text Adult",
                "field":"point_text_adult",
                "type":"TextArea",
                "tab":1,
            },
            "field7c":{
                "name":"Text Campaign",
                "field":"point_text_campaign",
                "type":"TextArea",
                "tab":3,
            },
            "field8":{
                "name":"OGG Kids",
                "field":"point_audio",
                "type":"SoundUpload",
                "tab":0,
            },
            "field8b":{
                "name":"OGG Adult",
                "field":"point_audio_adult",
                "type":"SoundUpload",
                "tab":1,
            },
            "field8c":{
                "name":"OGG Campaign",
                "field":"point_audio_campaign",
                "type":"SoundUpload",
                "tab":3,
            },
            "field9":{
                "name":"Image Kids",
                "field":"point_image",
                "type":"ImageSelect",
                "idfield":"point_image_id",
                "resize":{
                    width:1024,
                    height:576,
                    type:"fit",
                    quality:0.85,
                    bgcolor:"#ffffff"
                },
                "tab":0,
            },
            "field9b":{
                "name":"Image Adult",
                "field":"point_image_adult",
                "type":"ImageSelect",
                "idfield":"point_image_id_adult",
                "resize":{
                    width:1024,
                    height:576,
                    type:"fit",
                    quality:0.85,
                    bgcolor:"#ffffff"
                },
                "tab":1,
            },
            "field9c":{
                "name":"Image Campaign",
                "field":"point_image_campaign",
                "type":"ImageSelect",
                "idfield":"point_image_id_campaign",
                "resize":{
                    width:1024,
                    height:576,
                    type:"fit",
                    quality:0.85,
                    bgcolor:"#ffffff"
                },
                "tab":3,
            },
            "field10":{
                "name":"Video URL Kids",
                "field":"point_video",
                "type":"TextInput",
                "required":false,
                "tab":0,
            },
            "field10b":{
                "name":"Video URL Adult",
                "field":"point_video_adult",
                "type":"TextInput",
                "required":false,
                "tab":1,
            },
            "field10c":{
                "name":"Video URL Campaign",
                "field":"point_video_campaign",
                "type":"TextInput",
                "required":false,
                "tab":3,
            },
            "field11":{
                "name":"Section",
                "field":"point_section_id",
                "type":"DropDown",
                "subtype":"filter",
                "options": "sections",
                "emptyfirstoption":"Select a section",
                "defaultsubid":true,
                "validate":checkSection,
                "filter":true,
                "required":true,
                "list":true,
                "tab":2,
                "alphabetic":true,
                "large":true
                
            },
            "field12":{
                "name":"Categories",
                "field":"categories",
                "type":"DropDown",
                "options": "categories",
                "required":false,
                "list":false,
                "multiple":true,
                "tab":2
            },
            "field14":{
                "name":"Game Kid",
                "field":"point_game_id",
                "type":"DropDown",
                "filter":true,
                "options": "games",
                "required":false,
                "list":false,
                "tab":0,
                
            },
            "field15":{
                "name":"Game Adult",
                "field":"point_game_id_adult",
                "type":"DropDown",
                "options": "games",
                "filter":true,
                "required":false,
                "list":false,
                "tab":1,
                
            },
            "field15b":{
                "name":"Game Campaign",
                "field":"point_game_id_campaign",
                "type":"DropDown",
                "options": "games",
                "filter":true,
                "required":false,
                "list":false,
                "tab":3,
                
            },
            "field16":{
                "name":"Likes/Dislikes",
                "field":"point_likesdislikes",
                "type":"Display",
                "list":true,
                "tab":2,
                
            },
            "field17":{
                "name":"Active in campaigns",
                "field":"campaigns",
                "type":"Transfer",
                "options": "campaigns",
                "required":false,
                "list":false,
                "multiple":true,
                "tab":3
            },
        }
    }
}