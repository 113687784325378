export function checkComplete(type,data) {
    let add = "";
    let complete = true;
    if (type == "adult") {
        add = "_adult";
    }
    if (type == "campaign") {
        add = "_campaign";
    }

    if (data["point_game_id"+add] != 0 && data["point_game_id"+add] != null) {
        return true;
    }
    
    if (data["point_audio"+add] == "" || data["point_audio"+add] == null) {
        complete = false;
    }
    if (data["point_name"+add] == "" || data["point_name"+add] == null) {
        complete = false;
    }
    if (data["point_text"+add] == "" || data["point_text"+add] == null) {
       complete = false;
    }
    return complete;
}

export function PointState(data) {
   let ret = {};
   ret.type = "none";
   ret.state = "incomplete";
   ret.completetype = "none";


    if (data.point_kid == 1 && data.point_adult == 0) {
        ret.type = "kid";
    }
    else if (data.point_kid == 0 && data.point_adult == 1) {
        ret.type = "adult";
    }
    else if (data.point_kid == 1 && data.point_adult == 1) {
        ret.type = "both";
    }
    
   
    let complete_kid = checkComplete("kid",data);
    let complete_adult = checkComplete("adult",data);

    if (complete_kid && complete_adult) {
        ret.state = "complete";
    }
    else if (complete_kid) {
        ret.state = "complete";
        ret.completetype = "kid";
        
    }
    else if (complete_adult) {
        ret.state = "complete";
       
            ret.completetype = "adult";

    }
    return ret;
}
