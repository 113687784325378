import React, { useState, useRef, useEffect,useContext,createContext} from "react";
import styled from "styled-components";
import { media } from "../../utils";
import { GetList } from "../../utils/ApiFunctions";
import { P } from "../global/Paragraph";
import {Parsers} from "./fields/Fields";
import Pagination from "./Pagination";
import Filters from "./Filters";
import Search from "./Search";
import axios from "axios";
import mainjson from '../../config/main.js';
import {ucfirst,GetUrlParams,MakeUrlParams} from "../../utils/HelperFunctions"
import {DeleteRecord,GetRecord,SetRecord} from "../../utils/ApiFunctions";
import GoogleMapReact from 'google-map-react';
import {Marker} from 'google-map-react';
import MapPointer from './MapPointer';
export const FormDataContext = createContext({});
import {Viewer} from 'mapillary-js';
import "../../../src/styles/mapillary.css";
import SuggestionMapPointer from "./SuggestionMapPointer";

const Legend = styled.div`
  position:absolute;
  bottom:0px;
  left:0;
  z-index:5;
  padding:5px;
  font-size:12px;
  background-color:rgba(255,255,255,0.9);
`

const StreetViewClose = styled.div`
  -webkit-user-drag: none;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  background: none rgb(34, 34, 34);
  border: 0px;
  margin: 10px;
  padding: 0px;
  text-transform: none;
  appearance: none;
  position: absolute;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  height: 40px;
  width: 40px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  overflow: hidden;
  top: 0px;
  right: 50px;
  z-index:7;
`

const StreetViewNext = styled.div`
  -webkit-user-drag: none;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  background: none rgb(34, 34, 34);
  border: 0px;
  margin: 10px;
  padding: 0px;
  text-transform: none;
  appearance: none;
  position: absolute;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  height: 40px;
  width: 40px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  overflow: hidden;
  top: 0px;
  right: 00px;
  z-index:7;
`

// const StreetViewBig = styled.div`
//   -webkit-user-drag: none;
//   box-sizing: inherit;
//   -webkit-font-smoothing: antialiased;
//   background: none rgb(34, 34, 34);
//   border: 0px;
//   margin: 10px;
//   padding: 0px;
//   text-transform: none;
//   appearance: none;
//   position: absolute;
//   cursor: pointer;
//   user-select: none;
//   border-radius: 2px;
//   height: 40px;
//   width: 40px;
//   box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
//   overflow: hidden;
//   top: 0px;
//   right: 0px;
//   z-index:7;
// `


const Full = styled.div`
  width:100%;
  height:100%;
  position:absolute;
  top:0;
  left:0;
`


const StreetViewCloseIcon = styled.li`
  width:25px;
  height:25px;
  position:absolute;
  margin:auto;
  left:0;
  right:0;
  top:0;
  bottom:0;
  color:#666666;
  line-height:25px;
  font-size:25px;
`

const StreetViewNextIcon = styled.li`
  width:25px;
  height:25px;
  position:absolute;
  margin:auto;
  left:0;
  right:0;
  top:0;
  bottom:0;
  color:#666666;
  line-height:25px;
  font-size:25px;
`

const LegendText = styled.div`
  position:relative;
  left:10px;
`

const LegendLine = styled.div`
height:30px;
  display: flex;
  align-items: center;
  font-size:12px;
  padding-left:10px;
  padding-right:10px;
`

const AddPointButton = styled.div`
  -webkit-user-drag: none;
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
  background: none rgb(255, 255, 255);
  border: 0px;
  margin: 10px;
  padding: 0px;
  text-transform: none;
  appearance: none;
  position: absolute;
  cursor: pointer;
  user-select: none;
  border-radius: 2px;
  height: 40px;
  width: 40px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  overflow: hidden;
  top: 0px;
  right: 50px;
  z-index:3;

`
const AddPointButtonIMG = styled.div`
  background-image: url("/img/marker.png");
  background-repeat:no-repeat;
  background-size:contain;
  background-position: 50% 50%;
  width:25px;
  height:25px;
  margin:auto;
  position:absolute;
  left:0;
  top:0;
  right:0;
  bottom:0;

`

const Container = styled.div`
  width:100%;
  height:100%;
  overflow-y:scroll;
  display:flex;
  flex-direction:column;
  justify-content: space-evenly;
`;

const ListTable = styled.table`
  width:100%;
  color: #212529;
  text-align: left;
  font-size: 14px;
  box-sizing: border-box;
  outline: 0;
  background: #fff;
  padding: 14px 10px;
  box-shadow: 1px 0 20px rgba(0,0,0,.08);
  border-spacing: 0;
    border-collapse: collapse;  
`;

const ItemsTitle = styled.div`
  color:white;
  line-height:40px;
  padding-left:25px;
  `


const ListTableHead = styled.thead`
  cursor:pointer;
  font-weight:bold;
`;

const ListTableBody = styled.tbody`
  
`;

const StreetView = styled.div`
    width:75%;
    height:75%;
    position:absolute;
    bottom:0;
    left:0;
    z-index:6;
    display:none;

`;

const ListRow = styled.tr`
line-height: 1.5;
text-align: left;
font-size: 14px;
border-collapse: collapse;
color: #212529;
box-sizing: border-box;
outline: 0;
background-color: ${props => props.alternate == 0 ? "#e4e4e4" : "#fafafa"};
`;

const ListCol = styled.td`

`;

const TableContainer = styled.div`
flex: 2 1 auto;
overflow:scroll;
padding-left:25px;
padding-right:25px;

`;

const Option = styled.div`
  display:inline-block;
  padding-right:10px;
  cursor:pointer;
  padding-left:10px;
`;

const ExtraOption = styled.div`
  display:inline-block;
  padding-right:10px;
  cursor:pointer;
  text-decoration:underline;
`;

const ListColSmall = styled.td`
  width:100px;
`;

const Button = styled.button`
  background-color:white;
`;

const Head = styled.div`
  text-align:right;
  background-color:#505465;
  color: #212529;
  text-align: left;
  font-size: 14px;
  font-weight: 300;
  box-sizing: border-box;
  outline: 0;
  align-items: center;
  display: flex!important;
  padding: 0;
  width:100%;
  height:60px;
  flex: 0 0 60px;
`;

const InnerHead = styled.div`
color: #212529;
text-align: left;
font-size: 14px;
font-weight: 300;
box-sizing: border-box;
outline: 0;
margin-top: 0;
display: flex;
padding-left: 50px;
margin-bottom: 0;
list-style: none;
margin-right: auto!important;
flex-direction: row;

`

let maps;
let map;
let lat;
let lng;
let X;
let Y;
let currenti;
let addx;
let addy;
let currentpoints;
let currenttype;
let zoomTimeout;
let mapskey = "AIzaSyAs-flPumLiDhASZRgqizzUfcGy1BMzxQk";

export default function ListMap(props) {
    
    const [listData, setListData] = useState(null);
    const [zoom, setZoom] = useState(11);
    const [zooming, setZooming] = useState(false);
    const [checkLat, setCheckLat] = useState(0);
    const [checkLng, setCheckLng] = useState(0);
    const [points, setPoints] = useState([]);
    const [table, setTable] = useState(null);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState(null);
    const [start, setStart] = useState(0);
    const [totalSort, setTotalSort] = useState(null);
    const [count, setCount] = useState(null);
    const [editing, setEditing] = useState(null);
    const [suggestionAdded, setSuggestionAdded] = useState(false);
    const sorting = useRef();
    const extraData = useRef();
    const sortingField = useRef();
    const mapillaryData = useRef();
    const mapillaryId = useRef();  
    const streetview = useRef();
    const streetviewStreetview = useRef();
    const currentPoint = useRef();
    const streetviewMapillary = useRef();
    const mapillaryViewer = useRef();
    const changeCounter = useRef(0);
    const mapillaryDiv = useRef(null);
    const [limit, setLimit] = useState(100);
    const [filters, setFilters] = useState({});
    const [suggestion, setSuggestion] = useState(null);
    const feedget  = useRef();
    const nextButton  = useRef();
    const bigButton = useRef();
    const sv  = useRef();
    const panorama  = useRef();
    const timeout  = useRef();
    const centercet  = useRef(false);
    const draggable  = useRef(true);
    let pointerComponent;
    const params = GetUrlParams();
    const [mapcenter, setMapCenter] = useState({
        lat: 52.2130,
        lng: 5.2794
    });
    const defaultMapCenter = {
        lat: 52.2130,
        lng: 5.2794
    };
    //const zoom = useRef(11);
     

    function latLng2Point(ll, map) {
      var latLng = new google.maps.LatLng(ll);
      var topRight = map.getProjection().fromLatLngToPoint(map.getBounds().getNorthEast());
      var bottomLeft = map.getProjection().fromLatLngToPoint(map.getBounds().getSouthWest());
      var scale = Math.pow(2, map.getZoom());
      var worldPoint = map.getProjection().fromLatLngToPoint(latLng);
      return new google.maps.Point((worldPoint.x - bottomLeft.x) * scale, (worldPoint.y - topRight.y) * scale);
    }
    
    function point2LatLng(point, map) {
      var topRight = map.getProjection().fromLatLngToPoint(map.getBounds().getNorthEast());
      var bottomLeft = map.getProjection().fromLatLngToPoint(map.getBounds().getSouthWest());
      var scale = Math.pow(2, map.getZoom());
      var worldPoint = new google.maps.Point(point.x / scale + bottomLeft.x, point.y / scale + topRight.y);
      return map.getProjection().fromPointToLatLng(worldPoint);
    }

    function mouseMove(event) {
          
      X = event.pixel.x;
      Y = event.pixel.y;

      if (currenti != undefined && currenti != null && currentpoints.length > 0){
        let posx = X + addx;
        let posy = Y + addy;
        let latlng = point2LatLng({x:posx,y:posy},map);
        setCheckLat(latlng.lat());
        setCheckLng(latlng.lng());
        let np = [];
         for (var j = 0;j<currentpoints.length;j++) {
           let p = currentpoints[j];
          // console.log("currenttype ",currenttype);
           if (j==currenti) {
             if (currenttype == 1) {
              p.point_lat = latlng.lat();
              p.point_lng = latlng.lng();
             }
             else if (currenttype == 2) {
              p.point_lat_visible = latlng.lat();
              p.point_lng_visible = latlng.lng();
             }
           }
           np.push(p);
         }
   
        setPoints(np);
      }
    }

    function mapillaryErrorHandler(e){
      console.log("error ",e);
    }

    async function processSVData(data, status) {
      let returndata;
      if (data) {
        panorama.current.setPano(data.location.pano)
        //console.log("data",data)
        // panorama.current.setPov({
        //     // heading: 270,
        //     pitch: 0
        // });
        panorama.current.setVisible(true);
        streetview.current.style.display='block';
        streetviewMapillary.current.style.display = 'none';
        streetviewStreetview.current.style.display = 'block';
      }
      else {
        //console.log("no streetview data");
        streetview.current.style.display='none';

        let metadata_endpoint = "https://graph.mapillary.com";
        let x_dist = 0.00025;
        let y_dist = 0.00025;
        let testpoint=[currentPoint.current.point_lat,currentPoint.current.point_lng];
        let client_token = 'MLY|6728180237256039|42c8919baad8bbb428a9a17ee435cf4b';
        let headers= { "Authorization" : "OAuth "+client_token }

        let  url_imagesearch = metadata_endpoint+'/images';
        
        //console.log("headers ",headers);

        await axios({
          method: "get",
          url: url_imagesearch,
          headers:headers,
          params: {
            bbox:[
              (testpoint[1]-x_dist),
              (testpoint[0]-y_dist),
              (testpoint[1]+x_dist),
              (testpoint[0]+y_dist)
            ],
            fields:'id'

          }
        })
      .then((response) => {
         if (response.data) {
          //console.log('returndata',response.data);
          returndata = response.data;
         }
      })
      .catch((error) => {
          console.log("error "+error);
      });

      //console.log("na axios");

        if (returndata && returndata.data && returndata.data.length > 0 ) {

          if (returndata.data.length>1) {
            nextButton.current.style.display = 'block';
          }
        
          streetviewStreetview.current.style.display = 'none';
          streetviewMapillary.current.style.display = 'block';
          streetview.current.style.display='block';
          if (!mapillaryViewer.current) {
            
  
            let viewerData = {
              accessToken: "MLY|6728180237256039|42c8919baad8bbb428a9a17ee435cf4b",
              container: streetviewMapillary.current,
              component: {cover: true,
              pointer: {
                dragPan: true,
                scrollZoom: true,
                touchZoom: true,
              }},
            };
            
            mapillaryViewer.current = new Viewer(viewerData);

          }


          
          //console.log("mapillaryViewer ",mapillaryViewer);
          mapillaryData.current = returndata;
          mapillaryId.current = 0;
          mapillaryViewer.current.moveTo(returndata.data[0].id).catch(mapillaryErrorHandler);
          
          pointerComponent = mapillaryViewer.current.getComponent("pointer");
          //  pointerComponent.dragPan.disable();
          pointerComponent.dragPan.enable();

          var isEnabled = pointerComponent.dragPan.isEnabled;

          console.log("pointer ",isEnabled)
          
     
        }
        else {
          alert("No streetview available");
          streetview.current.style.display='none';
        }

       
      }
    }

    function nextPhoto(){
      mapillaryId.current++;
      if (!mapillaryData.current.data[ mapillaryId.current]) {
        mapillaryId.current = 0;
      }

      mapillaryViewer.current.moveTo(mapillaryData.current.data[mapillaryId.current].id).catch(mapillaryErrorHandler);
    }

    function handleApiLoaded(mapr,mapsr) {
        map = mapr;
        maps = mapsr;

        sv.current = new maps.StreetViewService();
        panorama.current = new maps.StreetViewPanorama(streetviewStreetview.current);
       // sv.current.getPanorama({location: {lat: 37.869, lng: -122.2547}, radius: 50}, processSVData);

        map.animatedZoom = false;

        maps.event.addListener(map, 'mousemove', (e)=>mouseMove(e));
        maps.event.addListener(map,"zoom_changed", () => zoomChanged());
            //console.log("xy",X,Y)
          
          //lat =  event.latLng.lat();
          //lng =  event.latLng.lng();
          //console.log('Lat: ' + event.latLng.lat() + ' Lng: ' + event.latLng.lng());
      //});
    }


    function GetPoints(){
      if (timeout.current) {
        clearTimeout(timeout.current);
      }   
      timeout.current = setTimeout(GetPoints2,10);
   }

   function GetFeed(){
      if (timeout.current) {
        clearTimeout(timeout.current);
      }   
      timeout.current = setTimeout(GetFeed2,10);
   }

   async function GetFeed2(){


    if (params.suggestionid) {
      let data = await GetRecord("suggestions",params.suggestionid);
      console.log("suggestion ",data);
      setSuggestion(data);
      map.setCenter({
        lat:data.data.suggestion_lat,
        lng:data.data.suggestion_lng,
    });
    }


    if (!feedget.current && params.subid != null) {
      let params = GetUrlParams();
      //console.log("params",params);
      //console.log("get feed ",start,limit,totalSort);
     // feedget.current = true;
      let data = await GetList(props.type,0,9999999,totalSort,search,{},params.subid);
      feedget.current = false;
      if (data.records) {
        setCount(data.records.count);
        props.extraSet(data.extra);
        console.log("data",data)
        setListData({data:data.records.data,extra:data.extra});
      }
    }
}



   function Edit(id){
    props.onEdit(id);
   }



   async function AddMarkerPoint(id,data) {
     console.log("add marker point",id,data.point_lat,data.point_lng);
     await UpdateRecord({point_lat_visible:data.point_lat,point_lng_visible:data.point_lng},id);
     GetFeed();
   }

   async function UpdatePosition(id,data) {
    console.log("update position",id,data.point_lat,data.point_lng);
    await UpdateRecord({point_lat:data.point_lat,point_lng:data.point_lng},id);
    GetFeed();
  }

  async function UpdateMarkerPosition(id,data) {
    console.log("update marker position",id,data.point_lat,data.point_lng);
    await UpdateRecord({point_lat_visible:data.point_lat_visible,point_lng_visible:data.point_lng_visible},id);
    GetFeed();
  }

  async function UpdateRange(id,data,amount) {
    console.log("update range to ",data.point_range+amount);
    await UpdateRecord({point_range:data.point_range+amount},id);
    GetFeed();
  }

  async function SetState(id,state,type) {
    console.log("update state to ",state);
    let update = {};
    if (type == 'adult') {
      update["point_adult"] = state;
    }
    if (type == 'kid') {
      update["point_kid"] = state;
    }

    await UpdateRecord(update,id);
    GetFeed();
  }

  

   async function Delete(id,name){
      var r = confirm("Are you certain you want to delete "+name +"?");
      if (r == true) {
        props.onEdit(null);
        let result = await DeleteRecord(props.data.apitype,id);
        GetFeed();
      }
   }

   async function DeleteMarker(id,name){
    var r = confirm("Are you certain you want to delete "+name +"?");
    if (r == true) {
      props.onEdit(null);
      await UpdateRecord({point_lat_visible:0,point_lng_visible:0},id);
      GetFeed();
    }
 }

   function clickSort(field) {
    
    if (field != sortingField.current) {
      sorting.current = "asc";
    }
    else {
      if (sorting.current == "desc") {
        sorting.current = "asc";
      }
      else {
        sorting.current = "desc";
      }
    }
    sortingField.current = field;
    setTotalSort(sortingField.current + " "+sorting.current);

   }

   function OpenExtaOption(id,value,extraoption) {
     let vars = {subid:id,subname:value};
     if (typeof window != "undefined") {
      //console.log("params1 = ",MakeUrlParams(vars,true));
        //alert("Redirect to "+extraoption.page +MakeUrlParams(vars,true));
        window.location.href = extraoption.page + "/"+MakeUrlParams(vars,true);
     }
   }
   
   function makeOptions(id,name){
      let options = [];
      if (props.data.options.indexOf("edit") != -1) {
        options.push(<Option color={"#008713"} key={"edit"+id} onClick={()=>Edit(id)}><i className="lni lni-highlight-alt"></i></Option>);
      }
      if (props.data.options.indexOf("delete") != -1) {
        options.push(<Option color={"#ff002c"} key={"delete"+id} onClick={()=>Delete(id,name)}><i className="lni lni-trash"></i></Option>);
      }
      if (props.data.options.indexOf("view") != -1) {
        options.push(<Option color={"#ff002c"} key={"view"+id} onClick={()=>Edit(id)}><i className="lni lni-eye"></i></Option>);
      }


      return options;
   }

   function GetId(data){
      return data[props.data.id];
   }
   
   function GetValue(data){
    return data[props.data.subidname];
   }
 

    function GetExtraOptions(id,value){
      let options = [];
      for (let i = 0;i<props.data.extraoptions.length;i++) {
        let extraoption = props.data.extraoptions[i];
        options.push(<ExtraOption  key={"extraoption"+i+"-"+id} onClick={()=>OpenExtaOption(id,value,extraoption)}>{extraoption.name}</ExtraOption>);
      }
      return options;
    }

  function ReplaceStuff(match,fieldname,data) {
    return data[fieldname];
  }

  function OpenListLink(data,link){
    link = link.replace(/\[(.+?)\]/i,(match,fieldname)=>ReplaceStuff(match,fieldname,data));
    window.location.href = link;
  }

   function BuildList(){
     if (!listData) {
       return;
     }
     if (listData.data.count == 0) {
         return;
     }

    

     if (!maps) {
         setTimeout(BuildList,500);
         return;
     }

     if (listData.data.length > 0 && centercet.current == false)
     {
        map.setCenter({
            lat:listData.data[0].point_lat,
            lng:listData.data[0].point_lng,
        });
        centercet.current = true;
      }

    let pnts = [];
      for (let i = 0;i<listData.data.length;i++) {
          let data = listData.data[i];
          pnts.push(data);
      }
            
      setPoints(pnts);

      setTable(table);
  
    }

    function changeFilter(filt){
      var clonedObj = { ...filt };
      var urlfilt = {};
      const keys = Object.keys(filt)
      for (const key of keys) {
        urlfilt["f-"+key] = filt[key];
      }
      updateUrl(urlfilt);
      setFilters(clonedObj);
    }

    function parseUrlFilters(params){
      let filt = {};
      const keys = Object.keys(params)
      for (const key of keys) {
        console.log("filters ",key)
        if (key.indexOf("f-") != -1) {
          console.log("filters jaaa")
            filt[key.replace("f-","")] = params[key];
        }
      }
      setFilters(filt);
    }

    function InsertNew(){
      console.log("insertnew");
      props.onEdit(-1);
    }

    function doSearch(term){
        setSearch(term);
    }

    function PageChange(page){
      if (page*limit != start) {
        setStart(page*limit);
        setPage(page);
      }
      
    }

    function updateUrl(vars) {
      if (typeof window != "undefined") {
        console.log("update url",vars);
        window.history.pushState("", "", window.location.pathname + MakeUrlParams(vars));
      }
    }

    function childMouseDown(){
      console.log('childmousedown');
    }

    

    function zoomChanged(){
      setZooming(true);
      if (zoomTimeout) {
        clearTimeout(zoomTimeout);
      }
      zoomTimeout = setTimeout(()=>setZooming(false),500);
      console.log('zoom',map.zoom);
      setZoom(map.zoom);
    }

    function markerClick(id,data,type) {
      console.log("markerclick ",id);
      if (id == null) {
        setEditing(null);
      }
      else {
      console.log("id",id,data,type);
      setEditing(id + "-"+type);
      }
    }

    function hideStreetView(){
      streetview.current.style.display='none';
    }

    function LookAroundMarker(suggestion) {
      let point = {};
      point.point_lat = suggestion.suggestion_lat;
      point.point_lng = suggestion.suggestion_lng;
      ShowStreetview(point);
    }

    function ShowStreetview(point,marker){
        let lat = point.point_lat;
        let lng = point.point_lng;
        if (marker) {
          lat = point.point_lat_visible;
          lng = point.point_lng_visible;
        }
        streetview.current.style.display='block';
        streetviewStreetview.current.style.display='block';
        nextButton.current.style.display = 'none';
        currentPoint.current = point;
        sv.current.getPanorama({location: {lat: lat, lng: lng}, radius: 50}, processSVData);
        // panorama.current.setPano({lat:lat,lng:lng});
        
    }

    function AddPoint(){
      //alert("add point")
      let params = GetUrlParams();
      let data = {
        point_name: "New point on "+map.getCenter().lat() + " "+map.getCenter().lng(),
        point_lat: map.getCenter().lat(),
        point_lng: map.getCenter().lng(),
        point_section_id: params.subid,
        point_kid:0,
        point_adult:0,
      }
      AddRecord(data);
      

      //console.log("add point on "+map.getCenter().lat());
    }

    async function UpdateRecord(fields,id){
      fields.extratype = "mapdrag";
      await SetRecord("points",fields,id);
    }

    async function AddRecord(fields,id,openrecord){
      let newrecord = await SetRecord("points",fields);
      if (openrecord) {
        console.log("open ",newrecord.data.id);
        Edit(newrecord.data.id);
      }
      GetFeed();
    }

    function moveMarker(i,x,y,type ){
      
      console.log("currentid",i,"currenttype ",type)
     let posx = X + x;
     let posy = Y + y; 
     addx = x;
     addy = y;
     currenti = i;
     currenttype = type;
    
    }

    function OnDragStop() {
      currenti=null;
    }

    function pointAction(id,actiontype) {
      console.log("point action ",id,actiontype);
      let point = {};
      for (var i =0;i<currentpoints.length;i++) {
        let p = currentpoints[i];
        if (id == p.point_id) {
          point = p;
        }
      }

      switch (actiontype) {
        case "edit":
          Edit(id);
        break;
        case "markerpoint":
          AddMarkerPoint(id,point);
        break;
        case "delete":
          Delete(id,point.point_name);
        break;
        case "deletemarker":
          DeleteMarker(id,"marker of "+point.point_name);
        break;
        case "moved":
          UpdatePosition(id,point);
          setCheckLng(0);
          setCheckLat(0);
        break;
        case "movedmarker":
          UpdateMarkerPosition(id,point);
          setCheckLng(0);
          setCheckLat(0);
        break;
        case "minrange":
          UpdateRange(id,point,-10);
        break;
        case "plusrange":
          UpdateRange(id,point,10);
        break;
        case "kidactive":
          SetState(id,1,"kid");
        break;
        case "kidinactive":
          SetState(id,0,"kid");
        break;
        case "adultactive":
          SetState(id,1,"adult");
        break;
        case "adultinactive":
          SetState(id,0,"adult");
        break;
        case "lookaround":
          ShowStreetview(point,false);
        break;
        case "lookaroundmarker":
          ShowStreetview(point,true);
        break;
        
      }
    }

    function createSuggestion(suggestion){
     // console.log("createSuggestion " + suggestion);
        setSuggestionAdded(true);
    
        let data = {
          point_name: suggestion.suggestion_title,
          point_lat: suggestion.suggestion_lat,
          point_lng: suggestion.suggestion_lng,
          point_text:suggestion.suggestion_desc,
          point_kid:0,
          point_adult:0,
        }
        AddRecord(data,null,true);
    }

    function focusOnActivePoint(){
      if (listData && listData.data && props.id && listData.data.length > 0) {
        console.log("focus on "+props.id);

        for (var i = 0;i<listData.data.length;i++) {
          let item = listData.data[i];
          console.log("check item! ",i,item.point_id,props.id,item.point_id == props.id);
          if (item.point_id == props.id) {
            map.setCenter({
                  lat:item.point_lat,
                  lng:item.point_lng,
            });
          }
        }
      }
    }


    useEffect(() => {
      if (changeCounter.current == 0) {
        changeCounter.current++;
        return;
      }
      console.log("getfeed vars set ",totalSort,start,search,filters,"loading ",feedget.current);
      let p = {
        "totalSort":totalSort,
        "page":page,
        "search":search
      }
      updateUrl(p);
      GetFeed();
     }, [totalSort,start,search]);

    useEffect(() => {
      BuildList();
      focusOnActivePoint();


   }, [listData]);


   useEffect(() => {
    console.log("filters changed",filters);
   }, [filters]);

   useEffect(() => {
    currentpoints = points;
 }, [points]);
   
    useEffect(() => {
      let params = GetUrlParams();
      let varsset = false;
      if (params.search) {
        setSearch(params.search);
        varsset = true;
      }
      if (params.page) {
        setStart(params.page*limit);
        setPage(params.page);
        varsset = true;
      }
      if (params.totalSort) {
        let splitsort = params.totalSort.split(" ");
        sorting.current = splitsort[1];
        sortingField.current = splitsort[0];
        setTotalSort(params.totalSort);
        varsset = true;
      }
      
      parseUrlFilters(params);

      if (!varsset) {
       GetFeed();
      }
      return function cleanup() {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
      }
    }, []);

    useEffect(() => {
      if (props.newUserCounter != 0) {
        InsertNew();
      }
   }, [props.newUserCounter]);

    useEffect(() => {
      //console.log("getfeed2 props.id = "+props.id)
      if (props.id == null) {
        GetFeed();
      }
      focusOnActivePoint();

      if (suggestionAdded == true && props.id == null) {
        window.location = "/suggestions";
      }
      
   }, [props.id]);

   useEffect(() => {
      if (props.countChanged) {
        props.countChanged(count);
      }
 }, [count]);
 
 function fullScreen(){
  alert("fillscreen");
 }

 function mapOptionsCreator(map) {
  return  {
    scrollwheel: true,
    mapTypeControl:true,
    mapTypeControlOptions: {
        //position: map.ControlPosition.BOTTOM_LEFT    // this makes the map type control disappear
    },
    draggable: true,
    rotateControl: false,
    scaleControl: true,
    streetViewControl: false,
    panControl: false,
  };
}


  return (
    <Container>
      <Head>
        <InnerHead>
      <Search callback={doSearch} val={search}></Search> <ItemsTitle>{params.subname} {count} {props.data.nameMultiple}</ItemsTitle>
      <Filters values={filters} callback={changeFilter} data={props.data.filters}></Filters>
      </InnerHead>
      </Head>
      <div style={{ position:"relative",height: '100%', width: '100%' }}>
      
      <AddPointButton onClick={AddPoint}><AddPointButtonIMG/></AddPointButton>
      <Legend><LegendLine><img src='/img/star-yellow.png'/><LegendText>Active, data complete (K = kids, A = adult)</LegendText></LegendLine><LegendLine><img src='/img/star-gray.png'/><LegendText>Inactive, data complete</LegendText></LegendLine><LegendLine><img src='/img/star-red.png'/><LegendText>Active, data incomplete</LegendText></LegendLine><LegendLine><img src='/img/star-pink.png'/><LegendText>Inactive, data incomplete</LegendText></LegendLine></Legend>
        <StreetView ref={streetview}>
          <Full ref={streetviewStreetview}>
         
          </Full>
          <Full ref={streetviewMapillary}>
            </Full>

            <StreetViewClose><StreetViewCloseIcon onClick={hideStreetView} className={"lni lni-close"}></StreetViewCloseIcon></StreetViewClose>
            <StreetViewNext ref={nextButton}><StreetViewNextIcon onClick={nextPhoto} className={"lni lni-arrow-right-circle"}></StreetViewNextIcon></StreetViewNext>
            {/* <StreetViewBig ref={bigButton}><StreetViewNextIcon onClick={fullScreen} className={"lni lni-full-screen"}></StreetViewNextIcon></StreetViewBig> */}
           
          </StreetView>
        <GoogleMapReact
          options={mapOptionsCreator}
          bootstrapURLKeys={{ key: mapskey}}
          defaultCenter={defaultMapCenter}
          zoom={zoom}
          mapTypeControl={true}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}   
         
            //onChildMouseDown={()=>{console.log("stop dragging");map.setOptions({draggable: false})}}
            //onChildMouseUp={()=>{console.log("start dragging");map.setOptions({draggable: true})}}
            // onChildMouseMove={::this.onCircleInteraction}>
        >
           

{/* <Marker onClick={this.onMarkerClick} name={'Current location'} /> */}

{/* {points.map((value, index) => {
  console.log("marker",Marker);
  // return <Marker
  // title={'The marker`s title will appear as a tooltip.'}
  // name={'SOMA'}
  // position={{lat: value.point_lat, lng: value.point_lng}} />
})} */}

        {suggestion &&
        <SuggestionMapPointer
          type={1}
          lat={suggestion.data.suggestion_lat}
          lng={suggestion.data.suggestion_lng}
          data={suggestion}
          draggable={false}
          map={map}
          zoom={zoom}
          onClick={createSuggestion}
          LookAroundMarker={LookAroundMarker}
          selectedid={props.id}
          >
          </SuggestionMapPointer>
        }

                
        {points.map((value, index) => {
              return <MapPointer
              filters={filters}
              type={1}
              lat={value.point_lat}
              lng={value.point_lng}
              data={value}
              draggable={true}
              onDragStart={()=>map.setOptions({draggable: false})}
              onDragStop={()=>{OnDragStop();map.setOptions({draggable: true})}}
              onMove={(i,x,y,type)=>moveMarker(i,x,y,type)}
              id={index}
              map={map}
              zoom={zoom}
              onClick={markerClick}
              editing={editing}
              onAction={pointAction}
              range={value.point_range}
              checkLat={checkLat}
              checkLng={checkLng}
              selectedid={props.id}
              key={value.point_id.toString()}>
            </MapPointer>
        })}
        {points.map((value, index) => {
              if (value.point_lat_visible != 0 && value.point_lat_visible != undefined) {
              return <MapPointer
              filters={filters}
              type={2}
              lat={value.point_lat_visible}
              lng={value.point_lng_visible}
              lato={value.point_lat}
              lngo={value.point_lng}
              data={value}
              draggable={true}
              onDragStart={()=>map.setOptions({draggable: false})}
              onDragStop={()=>{OnDragStop();map.setOptions({draggable: true})}}
              onMove={(i,x,y,type)=>moveMarker(i,x,y,type)}
              id={index}
              getpixels={(latlng)=>latLng2Point(latlng,map)}
              map={map}
              editing={editing}
              zoom={zoom}
              checkLat={checkLat}
              checkLng={checkLng}
              selectedid={props.id}
              onClick={markerClick}
              onAction={pointAction}
              zooming={zooming}
              key={value.point_id.toString()+"visible"}>
            </MapPointer>
              }
        })}
          {/* <AnyReactComponent
            lat={59.955413}
            lng={30.337844}
            text="My Marker"
          /> */}
        </GoogleMapReact>
       
      </div>
    </Container>
  );
}